#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.check-circle-animation {
	animation: animate-check-icon 1.25s ease-in-out;
}

@keyframes animate-check-icon {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	70% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		transform: scale(1) rotate(360deg);
	}
}

#html5qr-code-full-region__scan_region > img {
	margin: auto;
}

#html5-qrcode-button-camera-permission {
	background-color: #44818b;
	color: white;
	padding: 0.6rem;
	border-radius: 7px;
	margin: 0rem 0.5rem 1rem 0.5rem;
}

#html5-qrcode-anchor-scan-type-change {
	color: #44818b;
}

#html5-qrcode-button-camera-start {
	background-color: #44818b;
	color: white;
	padding: 0.6rem;
	border-radius: 7px;
	margin-bottom: 1rem;
}
#html5-qrcode-button-camera-stop {
	background-color: #44818b;
	color: white;
	padding: 0.6rem;
	border-radius: 7px;
	margin: 1rem;
}

#html5qr-code-full-region {
	border: unset !important;
}

.link-decoration:hover {
	text-decoration: none;
}
