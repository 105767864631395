.react-calendar {
	width: 950px;
	max-width: 100%;
	min-height: fit-content;
	background: rgb(255, 255, 255);
	font-family: Verdana, Arial, Helvetica, sans-serif;
	line-height: 1.125em;
	border-radius: 10px;
	padding: 5px;
	margin-bottom: 1rem;
}
.highlight {
	background-image: linear-gradient(#b5d2d7, #b5d2d7);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}
.react-calendar--doubleView {
	width: 900px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.react-calendar button {
	margin: 0;
	border-radius: 4px;
	height: 42px;
	outline: none;
}

.react-calendar button:enabled:hover {
	cursor: pointer;
}

.react-calendar__navigation {
	display: flex;
	height: 50px;
	margin: 0.5em 0 1em 0;
	border-bottom: 1px solid gainsboro;
}

.react-calendar__navigation button {
	min-width: 44px;
	background: none;
}

.react-calendar__navigation button:disabled {
	background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 1em;
}

.react-calendar__month-view__weekdays__weekday {
	padding-bottom: 1.5em;
	padding-top: 1em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.9em;
	font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
	color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
	color: #ababab;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}

.react-calendar__tile {
	max-width: 100%;
	padding: 10px 6.6667px;
	text-align: center;
	line-height: 16px;
	border-radius: 4px;
}

.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-image: linear-gradient(#e6e6e6, #e6e6e6);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}

.react-calendar__tile--now {
	/* background: #fdf7c3; */
	background-image: linear-gradient(#fdf7c3, #fdf7c3);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background-image: linear-gradient(#fdf7c3, #fdf7c3);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}

.react-calendar__tile--hasActive {
	background-image: linear-gradient(#6c8d93, #6c8d93);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background-image: linear-gradient(#a9d4ff, #a9d4ff);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}

.react-calendar__tile--active {
	background-image: linear-gradient(#6c8d93, #6c8d93);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background-image: linear-gradient(#6c8d93, #6c8d93);
	background-size: 90% 80%;
	background-position: center center;
	background-repeat: no-repeat;
}

.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}

@media screen and (max-width: 1200px) {
	.react-calendar {
		height: fit-content;
		padding: 0 2px 0 2px;
	}

	.react-calendar__navigation {
		margin: 0;
	}

	.react-calendar button {
		padding: 10px;
	}
}
