@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

header {
	width: 100%;
	color: #fff;
	background-color: darkslategray;
	padding: 1rem 2rem;
}

#begin-button {
	border: 0px;
	display: inline-block;
	position: relative;
	z-index: 1;
	border: 0px solid #000000;
	background-color: transparent;
	color: black;
	padding: 25px;
	margin-left: 20px;
	padding-bottom: 20px;
}
#begin-button::after {
	content: "'";
	color: transparent;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	z-index: -1;
	border-radius: 40px;
	background-color: #fbb0a9;
	transform: skew(30deg);
}
.buble {
	position: absolute;
	z-index: -1;
	right: -30%;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	background-color: #96bfc6;
}

.buble2 {
	position: absolute;
	z-index: -1;
	left: -35%;
	top: 20%;
	width: 0px;
	height: 0px;
	border-top: 140px solid transparent;
	border-right: 140px solid #96bfc6;
	border-bottom: 80px solid transparent;
	transform: rotate(70deg);
}

.chart-container {
	height: 85vh;
}

.loading-container,
.error-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.loading-image,
.error-image {
	max-width: 4000px;
	margin-bottom: 20px;
}

.loading-image {
	max-width: 3000px;
	margin-bottom: 15px;
	margin-top: 10px;
}

.loading-text,
.error-message {
	font-size: 20px;
	text-align: center;
}

.animate-card {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}